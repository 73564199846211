<template>
    <div class="interactive-manage">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="interactive-breadcrumb">
            <el-breadcrumb-item class="first">创作中心</el-breadcrumb-item>
            <el-breadcrumb-item class="second">互动管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="interactive-content">
            <div class="content-left">
                <InteractiveContentModule @changeAccount="getAccountMine" ref="InteractiveModule"></InteractiveContentModule>
            </div>
            <div class="content-right">
                <MineModule ref="mineModule"></MineModule>
            </div>
        </div>
    </div>
</template>

<script>
    import MineModule from '../../../components/creativecentermodule/MineModule.vue'
    import InteractiveContentModule from '../../../components/creativecentermodule/InteractiveContentModule.vue'
    export default {
        name: "InteractiveManage",
        components: {
            MineModule,
            InteractiveContentModule
        },
        mounted() {
            this.getAccountCurrent();
        },
        methods:{
            getAccountMine(type,platformId,id){
                this.$refs.mineModule.getAccountMine(type,platformId,id)
            },
            getAccountCurrent() {
                this.$httpStudent.axiosGetBy(this.$api.get_account, {platform_type: 1, type: 1},  (res) => {
                    if (res.code === 200) {
                        if (res.data.data.length > 0) {
                            let arr = res.data.data;
                            this.accountValue = arr[0].id;
                            this.$refs.mineModule.getAccountMine(1,1,arr[0].id);
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .interactive-manage {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 100px);
        .interactive-content {
            display: flex;
            justify-content: start;
            height: 1%;
            flex: 1;
            .content-left {
                display: flex;
                flex: 1;
                width: 1%;
                border-radius: 10px;
                background-color: #fff;
            }
        }
    }
</style>