<template>
    <div>
        <div class="title-text">粉丝列表</div>
        <el-table :data="fansList" style="width: 100%; margin: 10px 0;" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '46px', color: '#5F5C74'}"
                  :cell-style="{fontSize: '14px',color: '#16113F'}">
            <el-table-column align="left" label="头像/用户名称" width="200">
                <template slot-scope="scope">
                    <div class="person-info">
                        <div class="avatar-box">
                            <img :src="scope.row.avatar ? scope.row.avatar : require('../../assets/img/image/user_img.png')" alt="">
                        </div>
                        <div class="text-overflow name">{{scope.row.nickname}}</div>
                    </div>
                </template>
            </el-table-column>
<!--            <el-table-column prop="signature" align="center" label="性别" width="120">-->
<!--                <template slot-scope="scope">-->
<!--                    {{scope.row.gender === '1' ? '男' : '女'}}-->
<!--                </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column prop="city" align="center" label="城市" width="120"></el-table-column>-->
            <el-table-column prop="signature" align="center" label="签名"></el-table-column>
            <el-table-column prop="score" align="center" label="互动分" width="120"></el-table-column>
        </el-table>
        <div style="text-align: center; cursor: pointer" v-if="hasMore" @click="moreBtn">查看更多</div>
<!--        <div style="text-align: center" v-else>暂时没有更多了</div>-->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                fansList: [],
                hasMore: false,
                cursor: 0
            }
        },
        created() {
            // this.getFansList()
        },
        methods: {
            // 获取粉丝列表
            getFansList() {
                let params = {
                    account_id: Number(this.$route.query.id) || '',
                    cursor: this.cursor,
                    count: 10
                }
                this.$httpStudent.axiosGetBy(this.$api.tikhoknew_data_fans, params, (res) => {
                    if (res.code === 200) {
                        console.log("res", res.data)
                        this.fansList = [...this.fansList, ...res.data.list]
                        this.cursor = res.data.cursor
                        this.hasMore = res.data.has_more
                    } else {
                        this.$message.warning(res.message)
                        // setTimeout(()=>{
                        //     window.open(res.data.url, '抖音授权扫码', 'channelmode=0,directories=0,width=1000,height=500,top=200,left=200');
                        // },1000)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 查看更多
            moreBtn() {
                this.getFansList()
            },
        }
    }
</script>

<style scoped lang="scss">
    .title-text {
        color: #16113F;
        font-size: 16px;
    }
    .person-info {
        display: flex;
        align-items: center;
        .avatar-box {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            overflow: hidden;
            background: #F1F0FE;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .name {
            margin-left: 20px;
            width: 1%;
            flex: 1;
        }
    }
</style>
